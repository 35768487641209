import BaseController from '@/service/BaseService'
import UserRequest from '@/request/System/UserRequest'

const base = new BaseController()

export default {
  data () {
    return {
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 1表示弹框为添加，2表示弹框为修改
      // 表单信息
      form: {
        RoleId: '',
        UserName: '',
        NickName: '',
        AvatarArray: [],
        Password: '',
        Status: ''
      },
      // 表单验证规则
      rules: {
        role_id: [{
          required: true,
          message: '请输入角色id'
        }],
        UserName: [{
          required: true,
          message: '请输入账号'
        }],
        NickName: [{
          required: true,
          message: '请输入昵称'
        }],
        avatar_array: [{
          required: true,
          message: '请上传头像'
        }],
        Status: [{
          required: true,
          message: '请输入状态'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        Page: 1,
        Limit: 10,
        Total: 0,
        List: [],
        Select: {
          Status: {},
          Role: []
        }
      },

      // 表单筛选
      searchForm: {
        UserName: null,
        NickName: null,
        Status: null,
        CreateTimeArray: []
      }
    }
  },
  // 初始化函数
  created: async function () {
    await base.init(this, new UserRequest()).getList(this.pageData, this.searchForm)
  },
  methods: {
    // 导入事件
    importClick: async function ({ file }) {
      await base.importClick(file)
    },
    // 导出事件
    exportClick: async function () {
      await base.exportClick()
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    // 分页事件
    pagingClick: async function (val) {
      await base.pagingClick(val)
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}
